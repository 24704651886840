import {
  Box,
  Chip,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CONTEXT_ORDER,
  PRODUCT_STATUS,
  QUERY_TYPE,
} from "../../constants/commonConstants";
import { ROUTES_LABEL } from "../../constants/routesLabels";
import CustomAutoComplete from "../atoms/customAutoComplete";
import CustomButton from "../atoms/customButton";
import CustomDropDown from "../atoms/customDropDown";
import CustomTextField from "../atoms/customTextField";
import CustomTransferList from "../atoms/customTransferLIst";
import styles from "./dataDownload.module.scss";
import {
  searchDataDownloadProducts,
  downloadDataFile,
  getProductsByMaterialName,
  fetchProductDetailsData,
  fetchProductDetailsDataSuccess,
  getProductsByMaterialNameSuccess,
  fetchProductContentData,
  getCategoryProductSuccess,
  downloadDataFileSuccess,
  getContextDetails,
  setSelectedProductsDownload,
} from "../../state/actions";
import { FILE_NAME_PATTERN } from "../../constants/commonConstants";
import { useSelector } from "react-redux";
import {
  downloadExcelFile,
  downloadFile,
  getAvailabilityDetails,
} from "../helper/util";
import JSZip from "jszip";
import { select } from "redux-saga/effects";
var js2xmlparser = require("js2xmlparser");
var _ = require("lodash");

export const DataDownload = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataMapping: any = {
    materialName: "material_name",
    modelName: "model_name",
    uniqueModelCode: "unique_model_code",
    color: "color",
    categoryName: "category_name",
    productHierarchyCode: "product_hierarchy_code",
    shortDescription: "description",
    businessGroup: "sales_area",
    language: "language",
    longDescription: "long_description",
    listPrice: "price",
    currency: "currency",
    priceListType: "price_list_type",
    extendedPriceListType: "extended_price_list_type",
    accountNumber: "account_id",
    retailPrice: "retail_price",
    details: "details",
    salesStatus: "sales_status",
    minimumOrderQuantity: "min_order_quantity",
    masterCartonQuantity: "master_carton_quantity",
    availability: "availability",
    availabilityCode: "atp_status",
    availabilityStatus: "availability_status",
    atpDate: "atp_date",
    countryOfOrigin: "country_of_origin",
    commodityCode: "commodity_code",
    firstShipmentMonth: "first_shipment_month",
    firstShipmentDate: "first_shipment_date",
    tax: "tax",
    recycleTax: "recycle_tax",
    batteryTax: "battery_tax",
    fee: "fee",
    ecoFee: "eco_fee",
    visibleFee: "visible_fee",
    width: "width",
    grossWeight: "gross_weight",
    netWeight: "net_weight",
    weightUnit: "weight_unit",
    height: "height",
    length: "length",
    unitOfDimension: "unit_of_dimension",
    volume: "volume",
    volumeUnit: "volume_unit",
    earBatteryWeight: "ear_battery_weight",
    earElectronicAccessoryWeight: "ear_electronic_accessory_weight",
    earNonElectronicAccessoryWeight: "ear_non_electronic_accessory_weight",
    earNetWeight: "ear_net_weight",
    weightOfCardboard: "weight_of_cardboard",
    weightOfCorrugatedCardboard: "weight_of_corrugated_cardboard",
    weightOfNonRecycablePaper: "weight_of_non_recycable_paper",
    totalWeightOfPaper: "total_weight_of_paper",
    weightOfPaper: "weight_of_paper",
    plasticWeightOfCompounds: "plastic_weight_of_compounds",
    plasticEpsWeigth: "plastic_eps_weigth",
    plasticOtherWeight: "plastic_other_weigth",
    plasticPetWeight: "plastic_pet_weigth",
    plasticPeWeight: "plastic_pe_weigth",
    plasticPpWeight: "plastic_pp_weigth",
    plasticPsWeight: "plastic_ps_weigth",
    plasticPvcWeight: "plastic_pvc_weigth",
    plasticTotalWeight: "plastic_total_weigth",
    levies: "levies",
    tapeLevies: "tape_levies",
    packagingLevies: "packaging_levies",
    batteryLevies: "battery_levies",
    authorLevies: "author_levies",
    battery: "battery",
    batteryPerMaterial: "battery_per_material",
  };
  const ogConfig: any = {
    digitalAsset: {
      defaultDataTypes: ["identifiers"],
      mainDataTypes: [
        "descriptions",
        "featureBenefits",
        "images",
        "videos",
        "inTheBox",
        "awardsAndReviews",
        "ecoEnergy",
        "permalinkUrl",
        "techSpecs",
        "keyTechSpecs",
        "iconDescription",
        "proBenefit",
        "proOverview",
        "manuals",
        "relatedProducts",
      ],
      subDataTypes: {
        identifiers: [
          "titleIdentifiers",
          "materialName",
          "modelName",
          "eanCode",
          "categoryName",
          "locale",
          "sunriseDateTime",
        ],
        descriptions: [
          "titleDescriptions",
          "marketingProductName",
          "elevatorPitch",
          "topFeatures",
          "factualShortDescription",
        ],
        featureBenefits: [
          "titleFeatureBenefits",
          "featureBenefits",
          "headline",
          "body",
          "shot",
        ],
        inTheBox: ["titleInTheBox", "inTheBox"],
        awardsAndReviews: ["titleAwardsAndReviews", "awards", "expertReviews"],
        ecoEnergy: ["titleEcoEnergy", "ecoEnergy"],
        permalinkUrl: ["titlePermalinkUrl", "permalinkUrl"],
        relatedProducts: ["titleRelatedProducts", "accessories"],
        images: ["titleImages", "images"],
        videos: ["titleVideos", "videos"],
        techSpecs: ["titleTechSpecs", "techSpecs", "displayName", "value"],
        keyTechSpecs: ["titleKeyTechSpecs", "keyTechSpecs"],
        iconDescription: ["titleIconDescription", "iconDescription"],
        proBenefit: ["titleProBenefit", "proBenefit"],
        proOverview: ["titleProOverview", "proOverview"],
        manuals: ["titleManuals", "manuals", "description", "language", "url"],
      },
      fileTypes: ["EXCEL", "JSON", "XML", "CSV"],
    },
    productData: {
      mainDataTypes: [
        "metaData",
        "shortDescription",
        "longDescription",
        "price",
        "retailPrice",
        "details",
        "quantity",
        "availability",
        "shipmentDate",
        "tax",
        "fee",
        "metricalUnits",
        "earWeight",
        "otherWeight",
        "levies",
        "battery",
      ],
      subDataTypes: {
        metaData: [
          "titleMetaData",
          "materialName",
          "modelName",
          "uniqueModelCode",
          "color",
          "categoryName",
          "productHierarchyCode",
        ],
        shortDescription: ["titleShortDescription", "shortDescription"],
        longDescription: [
          "titleLongDescription",
          "businessGroup",
          "language",
          "longDescription",
        ],
        price: [
          "titlePrice",
          "businessGroup",
          "listPrice",
          "currency",
          "priceListType",
          "extendedPriceListType",
          "accountNumber",
        ],
        retailPrice: [
          "titleRetailPrice",
          "businessGroup",
          "retailPrice",
          "currency",
        ],
        details: [
          "titleDetails",
          "details",
          "businessGroup",
          "salesStatus",
          "minimumOrderQuantity",
        ],
        quantity: ["titleQuantity", "masterCartonQuantity"],
        availability: [
          "titleAvailability",
          "availability",
          "businessGroup",
          "availabilityCode",
          "availabilityStatus",
          "atpDate",
          "countryOfOrigin",
          "commodityCode",
        ],
        shipmentDate: [
          "titleShipmentDate",
          "firstShipmentMonth",
          "firstShipmentDate",
        ],
        tax: [
          "titleTax",
          "tax",
          "businessGroup",
          "recycleTax",
          "batteryTax",
          "currency",
        ],
        fee: [
          "titleFee",
          "fee",
          "businessGroup",
          "ecoFee",
          "visibleFee",
          "currency",
        ],
        metricalUnits: [
          "titleMetricalUnits",
          "width",
          "grossWeight",
          "netWeight",
          "weightUnit",
          "height",
          "length",
          "unitOfDimension",
          "volume",
          "volumeUnit",
        ],
        levies: [
          "titleLevies",
          "levies",
          "businessGroup",
          "tapeLevies",
          "packagingLevies",
          "batteryLevies",
          "authorLevies",
        ],
        earWeight: [
          "titleEarWeight",
          "earBatteryWeight",
          "earElectronicAccessoryWeight",
          "earNonElectronicAccessoryWeight",
          "earNetWeight",
        ],
        otherWeight: [
          "titleOtherWeight",
          "weightOfCardboard",
          "weightOfCorrugatedCardboard",
          "weightOfNonRecycablePaper",
          "totalWeightOfPaper",
          "weightOfPaper",
          "plasticWeightOfCompounds",
          "plasticEpsWeigth",
          "plasticOtherWeight",
          "plasticPetWeight",
          "plasticPeWeight",
          "plasticPpWeight",
          "plasticPsWeight",
          "plasticPvcWeight",
          "plasticTotalWeight",
        ],
        battery: ["titleBattery", "battery", "batteryPerMaterial"],
      },
      defaultDataTypes: [],
      fileTypes: ["EXCEL", "XML", "CSV", "TXT"],
    },
    sampleBusinessGroup: {
      excludedDataTypes: ["videos", "techSpecs"],
      includedDataTypes: ["retailPrice"],
    },
    reseller: {
      excludedDataTypes: ["retailPrice"],
    },
    dealer: {
      excludedDataTypes: ["retailPrice"],
    },
    hybrid: {
      excludedDataTypes: ["retailPrice"],
    },
    internal_admin: {
      excludedDataTypes: [],
    },
  };

  const sonyMenuItems = useSelector(
    (state: any) => state?.commonReducer?.sonyMenuItems
  );

  const displayMenuItems = (itemName: any) => {
    const menuItem = sonyMenuItems?.filter((item: any) => {
      return item?.sectionName === "DNET Data Download";
    });
    const subMenu = menuItem[0]?.subMenu?.filter((subItem: any) => {
      return subItem?.sectionName === itemName;
    });
    return subMenu?.length === 1;
  };

  useEffect(() => {
    setIsDownloadTriggered(false);
    dispatch(
      getContextDetails({
        key: CONTEXT_ORDER?.DATA_DOWNLOAD_PAGE,
      })
    );
  }, []);

  const contextDetails = useSelector(
    (state: any) => state?.commonReducer?.contextDetails
  );

  const digitalAssetDownloadAvailable = displayMenuItems(
    "DNET Digital Asset Download"
  );
  const productDataDownloadAvailable = displayMenuItems(
    "DNET Product Data Download"
  );
  const isVisibleBaseLink = contextDetails?.isVisibleBaseLink;
  const [errorMessage, setErrorMessage] = useState<any>("");

  //Selected products from product details page
  const checkedProducts = useSelector(
    (state: any) => state?.productReducer?.selectedProducts
  );
  const [selectedProducts, setSelectedProducts] = useState<any>([]);

  const [displayRetailPriceWarning, setDisplayRetailPriceWarning] =
    useState<any>(false);

  const [queryType, setQueryType] = useState<any>(QUERY_TYPE.DIGITAL_ASSET);

  //Used to switch tab values
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  //This will switch the tabs based on clicked menu
  useEffect(() => {
    const currentPath = window.location.pathname;
    if (
      currentPath ===
      `/${ROUTES_LABEL.DATA_DOWNLOAD}/${ROUTES_LABEL.DIGITAL_ASSET}`
    ) {
      setQueryType(QUERY_TYPE.DIGITAL_ASSET);
      setValue(0);
    } else if (
      currentPath ===
      `/${ROUTES_LABEL.DATA_DOWNLOAD}/${ROUTES_LABEL.PRODUCT_DATA}`
    ) {
      setQueryType(QUERY_TYPE.PRODUCT_DATA);
      setValue(1);
    }
  }, [window.location.pathname]);

  const productSearchResult = useSelector(
    (state: any) => state?.dataDownloadReducer?.productSearchResult
  );

  const productDataDownload = useSelector(
    (state: any) => state?.dataDownloadReducer?.productDataDownload
  );

  //On change of query type prepopulate the data based on selected query type if data is available, else fetch the data
  useEffect(() => {
    resetAllData();
    prepopulateData(queryType);
  }, [queryType]);

  const prodCategoryResponse = useSelector(
    (state: any) => state?.commonReducer?.prodCategoryResponse
  );

  //Prepopulate the data based on selected query type once the data is available
  useEffect(() => {
    const category: any = [];
    if (!_.isEmpty(prodCategoryResponse)) {
      prodCategoryResponse?.data?.catalogList?.length > 0 &&
        prodCategoryResponse?.data?.catalogList[0]?.children?.map(
          (product: any, index: any) => {
            category.push({
              value: product?.id,
              label: product?.displayName,
            });
          }
        );
    }
    setCatagories(category);
    populateLanguages(currentLocale?.split("_")[0]);
    prepopulateData(queryType);
  }, [prodCategoryResponse]);

  const [categories, setCatagories] = useState<any>([]);
  const [mainDataTypes, setMainDataTypes] = useState<any>([]);
  const [fileTypes, setFileTypes] = useState<any>([]);
  const [isInternalAdmin, setInternalAdmin] = React.useState<any>(false);
  const userType = useSelector(
    (state: any) => state?.commonReducer?.currentDealer?.accountFlag
  );
  useEffect(() => {
    if (userType == 0) {
      setInternalAdmin(true);
    }
  }, [userType]);

  const [selectedFileType, setSelectedFileType] = useState<any>("");
  const [languages, setLanguages] = useState<any>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<any>("");

  //Populate the data based on querytype
  const prepopulateData = (queryType: any) => {
    if (queryType === QUERY_TYPE.DIGITAL_ASSET) {
      const filteredMainDataTypes = !isInternalAdmin
        ? ogConfig?.digitalAsset?.mainDataTypes?.filter(
            (value: any) => value !== "retailPrice"
          )
        : ogConfig?.digitalAsset?.mainDataTypes;
      setMainDataTypes(filteredMainDataTypes);
      filteredMainDataTypes?.includes("retailPrice")
        ? setDisplayRetailPriceWarning(true)
        : setDisplayRetailPriceWarning(false);
      setFileTypes(ogConfig?.digitalAsset?.fileTypes);
      setSelectedFileType(ogConfig?.digitalAsset?.fileTypes[0]);
    } else {
      const filteredMainDataTypes = !isInternalAdmin
        ? ogConfig?.productData?.mainDataTypes?.filter(
            (value: any) => value !== "retailPrice"
          )
        : ogConfig?.productData?.mainDataTypes;
      setMainDataTypes(filteredMainDataTypes);
      filteredMainDataTypes?.includes("retailPrice")
        ? setDisplayRetailPriceWarning(true)
        : setDisplayRetailPriceWarning(false);
      setFileTypes(ogConfig?.productData?.fileTypes);
      setSelectedFileType(ogConfig?.productData?.fileTypes[0]);
    }
  };

  const currentLocale = useSelector(
    (state: any) => state?.commonReducer?.locale
  );
  //Form the translation labels based on value
  const populateLanguages = (locale: any) => {
    let langList: any = [];
    langList.push({
      label: t(`dataDownload.language.${locale}.text`),
      value: locale,
    });
    setLanguages(langList);
    setSelectedLanguage(langList[0]?.value);
  };

  //Populating the label for available types
  const [leftList, setLeftList] = useState<any>([]);
  const [rightList, setRightList] = useState<any>([]);
  const [dataTypeMapping, setDataTypeMapping] = useState<any>([]);

  useEffect(() => {
    let assetList: any = [];
    let mapping: any = [];
    mainDataTypes.forEach((ele: any) => {
      assetList.push(t(`dataDownload.dataType.${ele}.text`));
      mapping.push({
        label: t(`dataDownload.dataType.${ele}.text`),
        value: ele,
      });
    });
    setLeftList(assetList);
    setRightList([]);
    setDataTypeMapping(mapping);
  }, [mainDataTypes]);

  //radio button value : 1 = search products and 2 = select category
  const [radioDr, setRadioDr] = useState<any>("1");

  const [selectedDataTypes, setSelectedDataTypes] = useState<any>([]);
  //converts the labels to values so it can be sent during download
  const selectedListValueHandler = (values: any) => {
    let dataTypesValues: any = [];
    values.forEach((val: any) => {
      dataTypesValues.push(
        dataTypeMapping?.filter((ele: any) => ele?.label === val)[0]?.value
      );
    });
    setSelectedDataTypes(dataTypesValues);
  };

  //stores the filename entered
  const [fileName, setFileName] = useState<any>("");
  const fileNameHandler = (e: any) => {
    setFileName(e?.target?.value);
  };

  const productByMaterialName = useSelector(
    (state: any) => state?.productReducer?.productsByMaterialName
  );

  useEffect(() => {
    if (!_.isEmpty(productByMaterialName) && isDownloadTriggered) {
      if (queryType === QUERY_TYPE.DIGITAL_ASSET) {
        const productIndexList = productByMaterialName?.map(
          (product: any) => Object.values(product)[0]
        );
        let productNames: any = [];
        productIndexList.forEach((ele: any) => {
          if (ele?.product?.index?.materialName)
            productNames.push(ele?.product?.index?.materialName);
        });
        if (productNames?.length >= 1) {
          dispatch(fetchProductDetailsData({ productNames: productNames }));
        }
      }
    }
  }, [productByMaterialName]);

  const productDetailsData = useSelector(
    (state: any) => state?.productReducer?.productCompareList
  );

  useEffect(() => {
    return () => {
      resetAllData();
      dispatch(setSelectedProductsDownload([]));
      dispatch(getProductsByMaterialNameSuccess({}));
      dispatch(
        fetchProductDetailsDataSuccess({
          response: [],
          compare: true,
        })
      );
      // dispatch(getCategoryProductSuccess({}));
      dispatch(downloadDataFileSuccess([]));
    };
  }, [dispatch]);

  const getCategoryName = (materialName: any) => {
    for (let i = 0; i < productByMaterialName.length; i++) {
      if (Object.keys(productByMaterialName[i])[0] === materialName) {
        return productByMaterialName[i]?.[materialName];
      }
    }
  };

  useEffect(() => {
    if (!_.isEmpty(productDetailsData)) {
      if (!selectedDataTypes.includes("identifiers")) {
        selectedDataTypes.unshift("identifiers");
      }
      const filteredProducts = productDetailsData?.map((product: any) => {
        const filteredDetails: any = {};
        selectedDataTypes.forEach((mainDataType: any) => {
          ogConfig?.digitalAsset?.subDataTypes?.[mainDataType]?.forEach(
            (subDataType: any) => {
              if (
                !subDataType.includes("title") &&
                product?.[subDataType] !== undefined
              ) {
                filteredDetails[subDataType] = product[subDataType];
              }
            }
          );
        });
        // return { ...filteredDetails, materialName: product?.materialName };
        return filteredDetails;
      });
      setProductDetailsList(filteredProducts);
    }
  }, [productDetailsData]);

  const [productDetailsList, setProductDetailsList] = useState<any>([]);
  const [productDownloadList, setProductDownloadList] = useState<any>([]);

  useEffect(() => {
    if (!_.isEmpty(productDetailsList) && !_.isEmpty(selectedDataTypes)) {
      downloadData();
    }
  }, [productDetailsList]);

  const downloadData = () => {
    if (queryType === QUERY_TYPE.DIGITAL_ASSET) {
      const promises: Promise<void>[] = [];
      const zip = new JSZip();
      switch (selectedFileType) {
        case "EXCEL":
          const ExcelJS = require("exceljs");
          productDetailsList.forEach((product: any, index: any) => {
            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet("Sheet1");
            prepareDigitalAssetExcelData(sheet, product);

            if (productDetailsList.length == 1) {
              const file =
                fileName === "" || fileName === null
                  ? product?.materialName?.replaceAll('/','_')
                  : fileName;
              downloadExcelFile(workbook, `${file}.xlsx`);
            } else {
              const excelPromise: any = workbook.xlsx
                .writeBuffer()
                .then((data: any) => {
                  zip.file(`${product?.materialName?.replaceAll('/','_')}.xlsx`, data);
                });
              promises.push(excelPromise);
            }
          });
          break;
        case "JSON":
          productDetailsList.forEach((product: any, index: any) => {
            if (productDetailsList.length == 1) {
              const file =
                fileName === "" || fileName === null
                  ? product?.materialName?.replaceAll('/','_')
                  : fileName;
              downloadFile(
                JSON.stringify(product, null, 4),
                `${file}.json`,
                "text/json"
              );
            } else {
              const jsonBlob = new Blob([JSON.stringify(product, null, 4)], {
                type: "text/json",
              });
              const jsonPromise = new Promise((resolve) =>
                resolve(jsonBlob)
              ).then((data: any) => {
                zip.file(`${product?.materialName?.replaceAll('/','_')}.json`, data);
              });
              promises.push(jsonPromise);
            }
          });
          break;
        case "XML":
          productDetailsList.forEach((product: any, index: any) => {
            if (productDetailsList.length == 1) {
              const file =
                fileName === "" || fileName === null
                  ? product?.materialName?.replaceAll('/','_')
                  : fileName;
              const xmlData = js2xmlparser.parse("product", product);
              downloadFile(xmlData, `${file}.xml`, "text/xml");
            } else {
              const xmlData = js2xmlparser.parse("product", product);
              const xmlBlob = new Blob([xmlData], { type: "text/xml" });
              const xmlPromise = new Promise((resolve) =>
                resolve(xmlBlob)
              ).then((data: any) => {
                zip.file(`${product?.materialName?.replaceAll('/','_')}.xml`, data);
              });
              promises.push(xmlPromise);
            }
          });
          break;
        case "CSV":
          const file =
            fileName === "" || fileName === null
              ? productDetailsList?.length === 1
                ? productDetailsList[0]?.materialName
                : "digitalAssetDownload"
              : fileName;
          let csvContent =
            "data:text/csv;charset=utf-8," +
            "\ufeff" +
            prepareDigitalAssetCsvData(productDetailsList);
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", `${file}.csv`);
          document.body.appendChild(link);
          link.click();
          break;
        default:
          break;
      }
      selectedFileType !== "CSV" &&
        productDetailsList?.length > 1 &&
        Promise.all(promises).then(() => {
          zip.generateAsync({ type: "blob" }).then((content: any) => {
            const file =
              fileName === "" || fileName === null
                ? "digitalAssestDownload"
                : fileName;
            const url = window.URL.createObjectURL(content);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `${file}.zip`;
            anchor.click();
            window.URL.revokeObjectURL(url);
            anchor.remove();
          });
        });
    } else {
      const promises: Promise<void>[] = [];
      const zip = new JSZip();
      switch (selectedFileType) {
        case "EXCEL":
          const ExcelJS = require("exceljs");
          productDataDownload.forEach((product: any, index: any) => {
            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet("Sheet1");
            prepareProductDownloadExcelData(sheet, product);

            if (productDataDownload.length == 1) {
              const file =
                fileName === "" || fileName === null
                  ? product?.material_name?.replaceAll('/','_')
                  : fileName;
              downloadExcelFile(workbook, `${file}.xlsx`);
            } else {
              const excelPromise: any = workbook.xlsx
                .writeBuffer()
                .then((data: any) => {
                  zip.file(`${product?.material_name?.replaceAll('/','_')}.xlsx`, data);
                });
              promises.push(excelPromise);
            }
          });
          break;
        case "XML":
          productDataDownload.forEach((product: any, index: any) => {
            let productToParse: any = {};
            selectedDataTypes.map((mainDataTypes: any) => {
              productToParse[mainDataTypes] = {};
              ogConfig?.productData.subDataTypes?.[mainDataTypes].forEach(
                (subDataType: any) => {
                  if (subDataType === "availabilityStatus") {
                    //row.push(product["atp_status"] ? checkStatus(product["atp_status"]?.toString()) : "");
                    productToParse[mainDataTypes][subDataType] = product[
                      "atp_status"
                    ]
                      ? checkStatus(product["atp_status"]?.toString())
                      : "";
                  } else if (!subDataType?.includes("title")) {
                    productToParse[mainDataTypes][subDataType] = product?.[
                      dataMapping?.[subDataType]
                    ]
                      ? product[dataMapping[subDataType]]
                      : "";
                  }
                }
              );
            });
            if (productDataDownload.length == 1) {
              const file =
                fileName === "" || fileName === null
                  ? product?.material_name?.replaceAll('/','_')
                  : fileName;
              const xmlData = js2xmlparser.parse("product", productToParse);
              downloadFile(xmlData, `${file}.xml`, "text/xml");
            } else {
              const xmlData = js2xmlparser.parse("product", productToParse);
              const xmlBlob = new Blob([xmlData], { type: "text/xml" });
              const xmlPromise = new Promise((resolve) =>
                resolve(xmlBlob)
              ).then((data: any) => {
                zip.file(`${product?.material_name?.replaceAll('/','_')}.xml`, data);
              });
              promises.push(xmlPromise);
            }
          });
          break;
        case "CSV":
          const file =
            fileName === "" || fileName === null
              ? productDataDownload?.length === 1
                ? productDataDownload[0]?.material_name
                : "productDataDownload"
              : fileName;
          let csvContent =
            "data:text/csv;charset=utf-8," +
            "\ufeff" +
            prepareProductDownloadCsvData(productDataDownload);
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", `${file}.csv`);
          document.body.appendChild(link);
          link.click();
          break;
        case "TXT":
          const fileTxt =
            fileName === "" || fileName === null
              ? productDataDownload?.length === 1
                ? productDataDownload[0]?.material_name
                : "productDataDownload"
              : fileName;
          let txtContent = prepareProductDownloadCsvData(productDataDownload);
          const rows = txtContent.split("\n");
          const quotedRows = rows.map((row: any) => {
            const fields = row.split(",");
            const quotedFields = fields.map((field: any) => `"${field}"`);
            return quotedFields.join(",");
          });
          let quotedContent = quotedRows.join("\n");
          downloadFile(quotedContent, `${fileTxt}.txt`, "text/plain");

          break;
        default:
          break;
      }
      selectedFileType !== "CSV" &&
        selectedFileType !== "TXT" &&
        productDataDownload?.length > 1 &&
        Promise.all(promises).then(() => {
          zip.generateAsync({ type: "blob" }).then((content: any) => {
            const file =
              fileName === "" || fileName === null
                ? "productDataDownload"
                : fileName;
            const url = window.URL.createObjectURL(content);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `${file}.zip`;
            anchor.click();
            window.URL.revokeObjectURL(url);
            anchor.remove();
          });
        });
    }
  };

  const checkStatus = (status: any) => {
    switch (status) {
      case PRODUCT_STATUS.PRODUCT_AVAILABILITY:
        return t("dnet.available.text");
      case PRODUCT_STATUS.PRODUCT_AVAILABILITY_ON_KNOWN_DATE:
        return t("dnet.next_available_stock.text");
      case PRODUCT_STATUS.PRODUCT_UNAVAILABLE:
        return t("dnet.contact_sony_for_avb.text");
      case PRODUCT_STATUS.PRODUCT_SUBJECT_TO_MANUAL_ALLOCATION:
        return t("dnet.contact_sony_for_avb.text");
      case PRODUCT_STATUS.PRODUCT_AVAILABLE_BUT_SUBJECT_TO_QUOTA:
        return t("dnet.current_stock.text");
      case PRODUCT_STATUS.PRODUCT_AVAILABLE_ON_KNOWN_DATE_AND_SUBJECT_TO_QUOTA:
        return t("dnet.next_available_stock.text");
      case PRODUCT_STATUS.PRODUCT_PRE_SALES:
        return t("dnet.new_launch.text");
      default:
        return "";
    }
  };

  const prepareProductDownloadCsvData = (productList: any) => {
    const subSelectedDataType: any = [];
    let data: any = [];

    selectedDataTypes.map((mainDataTypes: any) => {
      ogConfig?.productData.subDataTypes?.[mainDataTypes].forEach(
        (subDataType: any) => {
          !subDataType?.includes("title") &&
            subSelectedDataType.push(subDataType);
        }
      );
    });

    let header = subSelectedDataType.map((subDataTypes: any) =>
      t(`dataDownload.dataType.${subDataTypes}.text`)
    );

    productList?.map((product: any) => {
      let row: any = [];
      selectedDataTypes.forEach((mainDataTypes: any) => {
        ogConfig?.productData.subDataTypes?.[mainDataTypes].forEach(
          (subDataType: any) => {
            if (!subDataType?.includes("title")) {
              if (subDataType === "availabilityStatus") {
                row.push(
                  product["atp_status"]
                    ? checkStatus(product["atp_status"]?.toString())
                    : ""
                );
              } else {
                row.push(
                  product[dataMapping?.[subDataType]]
                    ? product[dataMapping[subDataType]]?.toString()
                    : ""
                );
              }
            }
          }
        );
      });
      data.push(row);
    });
    let replacedArr: any = data.map((row: any) =>
      row.map((field: any) => field?.replaceAll(",", ";"))
    );

    return (
      header + "\n" + replacedArr.map((row: any) => row.join(",")).join("\n")
    );
  };

  const prepareProductDownloadExcelData = (sheet: any, product: any) => {
    let headerRows: any = [];

    selectedDataTypes.map((mainDataTypes: any) => {
      let subDataTypeArr = ogConfig?.productData.subDataTypes?.[mainDataTypes];
      if (
        (subDataTypeArr.includes(mainDataTypes) &&
          subDataTypeArr.length !== 2 &&
          mainDataTypes !== "battery") ||
        mainDataTypes === "price"
      ) {
        let subDataTypeProductData: any = [];
        let subArr: any = [];
        subDataTypeArr.forEach((subDataType: any) => {
          if (
            mainDataTypes !== subDataType ||
            subDataType === "battery" ||
            subDataType === "longDescription" || subDataType === "retailPrice" 
          ) {
            subArr.push(t(`dataDownload.dataType.${subDataType}.text`));
            subDataType?.includes("title")
              ? subDataTypeProductData.push("")
              : subDataType === "availabilityStatus"
              ? subDataTypeProductData.push(
                  product["atp_status"]
                    ? checkStatus(product["atp_status"]?.toString())
                    : ""
                )
              : subDataTypeProductData.push(
                  product?.[dataMapping?.[subDataType]]
                );
          }
        });
        setMainHeaderStyle(sheet.addRow(subArr), headerRows);
        sheet.addRow(subDataTypeProductData);
      } else {
        ogConfig?.productData.subDataTypes?.[mainDataTypes].forEach(
          (subDataType: any) => {
            subDataType?.includes("title")
              ? setMainHeaderStyle(
                  sheet.addRow([
                    t(`dataDownload.dataType.${subDataType}.text`),
                  ]),
                  headerRows
                )
              : subDataType === "availabilityStatus"
              ? //row.push(product["atp_status"] ? checkStatus(product["atp_status"]?.toString()) : "");
                sheet.addRow([
                  t(`dataDownload.dataType.${subDataType}.text`),
                  product["atp_status"]
                    ? checkStatus(product["atp_status"]?.toString())
                    : "",
                ])
              : sheet.addRow([
                  t(`dataDownload.dataType.${subDataType}.text`),
                  product?.[dataMapping?.[subDataType]]
                    ? product[dataMapping[subDataType]]
                    : "",
                ]);
          }
        );
      }
    });

    //set the width of columns
    for (let i = 1; i <= sheet.actualColumnCount; i++) {
      const col = sheet.getColumn(i);
      col.width = 30;
    }

    //set the background color for subheaders
    for (let i = 1; i <= sheet.actualRowCount; i++) {
      if (!headerRows.includes(i)) {
        const row = sheet.getRow(i);
        row.getCell(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "969696" },
          bgColor: { argb: "969696" },
        };
        row.getCell(1).font = {
          bold: true,
        };
      }
    }
  };

  const prepareDigitalAssetExcelData = (sheet: any, product: any) => {
    let headerRows: any = [];

    //Identifiers
    setMainHeaderStyle(
      sheet.addRow([t("dataDownload.dataType.titleIdentifiers.text")]),
      headerRows
    );
    product?.materialName &&
      sheet.addRow([
        t("dataDownload.dataType.materialName.text"),
        product?.materialName,
      ]);
    product?.modelName &&
      sheet.addRow([
        t("dataDownload.dataType.modelName.text"),
        product?.modelName,
      ]);
    product?.eanCode &&
      sheet.addRow([t("dataDownload.dataType.eanCode.text"), product?.eanCode]);
    sheet.addRow([
      t("dataDownload.dataType.categoryName.text"),
      product?.categoryName,
    ]);
    product?.locale &&
      sheet.addRow([t("dataDownload.dataType.locale.text"), product?.locale]);
    product?.sunriseDateTime &&
      sheet.addRow([
        t("dataDownload.dataType.sunriseDateTime.text"),
        product?.sunriseDateTime,
      ]);

    //Description
    if (selectedDataTypes.includes("descriptions")) {
      setMainHeaderStyle(
        sheet.addRow([t("dataDownload.dataType.titleDescriptions.text")]),
        headerRows
      );
      product?.marketingProductName &&
        sheet.addRow([
          t("dataDownload.dataType.marketingProductName.text"),
          product?.marketingProductName,
        ]);
      product?.elevatorPitch &&
        sheet.addRow([
          t("dataDownload.dataType.elevatorPitch.text"),
          product?.elevatorPitch,
        ]);
      product?.topFeatures &&
        sheet.addRow([
          t("dataDownload.dataType.topFeatures.text"),
          product?.topFeatures ? product?.topFeatures[0] : "",
        ]);
      for (let i = 1; i < product?.topFeatures?.length; i++) {
        product?.topFeatures && sheet.addRow(["", product?.topFeatures[i]]);
      }
      product?.factualShortDescription &&
        sheet.addRow([
          t("dataDownload.dataType.factualShortDescription.text"),
          product?.factualShortDescription,
        ]);
    }
    //Feature Benefits
    if (selectedDataTypes.includes("featureBenefits")) {
      setMainHeaderStyle(
        sheet.addRow([
          t("dataDownload.dataType.titleFeatureBenefits.text"),
          t("dataDownload.dataType.headline.text"),
          t("dataDownload.dataType.body.text"),
          t("dataDownload.dataType.shot.text"),
        ]),
        headerRows
      );
      product?.featureBenefits &&
        product?.featureBenefits?.forEach((ele: any) => {
          product?.featureBenefits &&
            sheet.addRow(["", ele?.headline, ele?.bodyCopy, ele?.shot]);
        });
    }

    //Images
    if (selectedDataTypes.includes("images")) {
      setMainHeaderStyle(
        sheet.addRow([t("dataDownload.dataType.titleImages.text")]),
        headerRows
      );
      product?.images &&
        Object.keys(product?.images).forEach((ele: any) => {
          product?.images && sheet.addRow([ele, product?.images[ele][0]]);
          for (let i = 1; i < product?.images[ele]?.length; i++) {
            sheet.addRow(["", product?.images[ele][i]]);
          }
        });
    }

    //Videos
    if (selectedDataTypes.includes("videos")) {
      setMainHeaderStyle(
        sheet.addRow([t("dataDownload.dataType.titleVideos.text")]),
        headerRows
      );
      product?.videos &&
        sheet.addRow([
          "externalUrl",
          product?.videos?.[0]?.videoFamily?.externalUrl,
        ]);
    }
    if (selectedDataTypes.includes("inTheBox")) {
      setMainHeaderStyle(
        sheet.addRow([t("dataDownload.dataType.titleInTheBox.text")]),
        headerRows
      );
      product?.inTheBox &&
        product?.inTheBox?.forEach((ele: any) => {
          product?.inTheBox && sheet.addRow(["", ele]);
        });
    }

    //Awards And reviews
    if (selectedDataTypes.includes("awardsAndReviews")) {
      setMainHeaderStyle(
        sheet.addRow([t("dataDownload.dataType.titleAwardsAndReviews.text")]),
        headerRows
      );
      if (product?.awards?.length > 0) {
        sheet.addRow([
          t("dataDownload.dataType.awards.text"),
          product?.awards[0],
        ]);
        for (let i = 1; i < product?.awards?.length; i++) {
          sheet.addRow(["", product?.awards[i]]);
        }
      }
      if (product?.expertReviews?.length > 0) {
        product?.expertReviews &&
          sheet.addRow([
            t("dataDownload.dataType.expertReviews.text"),
            product?.expertReviews[0],
          ]);
        for (let i = 1; i < product?.expertReviews?.length; i++) {
          product?.expertReviews &&
            sheet.addRow(["", product?.expertReviews[i]]);
        }
      }
    }

    //TODO - Need data to cross check
    //Eco Energy
    if (selectedDataTypes.includes("ecoEnergy")) {
      setMainHeaderStyle(
        sheet.addRow([t("dataDownload.dataType.titleEcoEnergy.text")]),
        headerRows
      );
      if (!_.isEmpty(product?.ecoEnergy)) {
        Object.keys(product?.ecoEnergy).forEach((ele: any) => {
          sheet.addRow([ele, product?.ecoEnergy[ele]]);
        });
      }
    }

    //Global product page
    if (selectedDataTypes.includes("permalinkUrl")) {
      setMainHeaderStyle(
        sheet.addRow([t("dataDownload.dataType.titlePermalinkUrl.text")]),
        headerRows
      );

      if (product?.permalinkUrl) {
        product?.permalinkUrl &&
          sheet.addRow([
            t("dataDownload.dataType.permalinkUrl.text"),
            product?.permalinkUrl,
          ]);
      }
    }

    //Technical specification
    if (selectedDataTypes.includes("techSpecs")) {
      setMainHeaderStyle(
        sheet.addRow([t("dataDownload.dataType.titleTechSpecs.text")]),
        headerRows
      );
      if (product?.techSpecs?.length > 0) {
        product?.techSpecs.forEach((ele: any) => {
          product?.techSpecs &&
            sheet.addRow([
              ele?.displayName,
              ele?.specs?.[0]?.displayName,
              ele?.specs?.[0]?.value?.toString(),
            ]);
          for (let i = 1; i < ele?.specs?.length; i++) {
            sheet.addRow([
              "",
              ele?.specs?.[i]?.displayName,
              ele?.specs?.[i]?.value?.toString(),
            ]);
          }
        });
      }
    }
    //key tech specification
    if (selectedDataTypes.includes("keyTechSpecs")) {
      setMainHeaderStyle(
        sheet.addRow([
          t("dataDownload.dataType.titleKeyTechSpecs.text"),
          t("dataDownload.dataType.displayName.text"),
          t("dataDownload.dataType.value.text"),
        ]),
        headerRows
      );
      if (product?.keyTechSpecs?.length > 0) {
        product?.keyTechSpecs.forEach((ele: any) => {
          product?.keyTechSpecs &&
            sheet.addRow(["", ele?.displayName, ele?.value?.toString()]);
        });
      }
    }

    //Icon description
    if (selectedDataTypes.includes("iconDescription")) {
      setMainHeaderStyle(
        sheet.addRow([t("dataDownload.dataType.titleIconDescription.text")]),
        headerRows
      );
      if (product?.iconDescription?.length > 0) {
        product?.iconDescription.forEach((ele: any) => {
          sheet.addRow(["", ele]);
        });
      }
    }
    //Pro Benefit
    if (selectedDataTypes.includes("proBenefit")) {
      setMainHeaderStyle(
        sheet.addRow([t("dataDownload.dataType.titleProBenefit.text")]),
        headerRows
      );
      if (product?.proBenefit?.length > 0) {
        product?.proBenefit.forEach((ele: any) => {
          product?.proBenefit && sheet.addRow(["", ele?.bodyCopy]);
        });
      }
    }
    //Pro Overview
    if (selectedDataTypes.includes("proOverview")) {
      setMainHeaderStyle(
        sheet.addRow([t("dataDownload.dataType.titleProOverview.text")]),
        headerRows
      );
      if (product?.proOverview?.length > 0) {
        product?.proOverview.forEach((ele: any) => {
          product?.proOverview && sheet.addRow(["", ele?.bodyCopy]);
        });
      }
    }

    //related products
    if (selectedDataTypes.includes("relatedProducts")) {
      setMainHeaderStyle(
        sheet.addRow([t("dataDownload.dataType.titleRelatedProducts.text")]),
        headerRows
      );
      if (product?.accessories?.length > 0) {
        product?.accessories.forEach((ele: any) => {
          product?.accessories &&
            sheet.addRow([
              "",
              ele?.productDisplayName,
              ele?.headlineDescription,
            ]);
        });
      }
    }

    //set the width of columns
    for (let i = 1; i <= sheet.actualColumnCount; i++) {
      const col = sheet.getColumn(i);
      col.width = 30;
    }

    //set the background color for subheaders
    for (let i = 1; i <= sheet.actualRowCount; i++) {
      if (!headerRows.includes(i)) {
        const row = sheet.getRow(i);
        row.getCell(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "969696" },
          bgColor: { argb: "969696" },
        };
        row.getCell(1).font = {
          bold: true,
        };
      }
    }
  };

  const setMainHeaderStyle = (row: any, headerRows: any) => {
    headerRows.push(row?._number);
    row.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "000000" },
      bgColor: { argb: "000000" },
    };
    row.font = {
      color: { argb: "FFFFFF" },
      bold: true,
    };
  };
  const prepareDigitalAssetCsvData = (productData: any) => {
    const header: any = [];
    let data: any = [];

    selectedDataTypes.map((mainDataTypes: any) => {
      if (
        mainDataTypes === "featureBenefits" ||
        mainDataTypes === "techSpecs"
      ) {
        header.push(mainDataTypes);
      } else {
        ogConfig?.digitalAsset.subDataTypes?.[mainDataTypes].forEach(
          (subDataType: any) => {
            !subDataType?.includes("title") && header.push(subDataType);
          }
        );
      }
    });
    productData?.map((product: any) => {
      let row: any = [];
      header.forEach((ele: any) => {
        switch (ele) {
          case "topFeatures":
          case "inTheBox":
          case "awards":
          case "expertReviews":
          case "iconDescription":
            product[ele]?.length > 0
              ? row.push(product[ele]?.join("|"))
              : row.push("");
            break;
          case "featureBenefits":
            let bene: any = [];
            if (product[ele]?.length > 0) {
              product[ele]?.forEach((feature: any) => {
                let row: any = [];
                Object.keys(feature)?.forEach((key: any) => {
                  row.push(key + ":" + feature[key]);
                });
                bene.push(row?.join("~"));
              });
              row.push(bene?.join("|"));
            } else {
              row.push("");
            }
            break;
          case "images":
            if (!_.isEmpty(product[ele])) {
              let imageList: any = [];
              Object.keys(product[ele])?.forEach((type: any) => {
                imageList.push(type + ":" + product?.images[type]?.join("~"));
              });
              row.push(imageList?.join("|"));
            } else {
              row.push("");
            }
            break;
          case "videos":
            if (!_.isEmpty(product[ele])) {
              row.push(
                `externalUrl:${product[ele]?.[0]?.videoFamily?.externalUrl}`
              );
            } else {
              row.push("");
            }
            break;
          case "ecoEnergy":
            if (!_.isEmpty(product[ele])) {
              let energyDetails: any = [];
              Object.keys(product[ele]).forEach((key: any) => {
                energyDetails.push(key + ":" + product[ele][key]);
              });
              row.push(energyDetails?.join("|"));
            } else {
              row.push("");
            }
            break;
          case "techSpecs":
            if (product[ele]?.length > 0) {
              let specList: any = [];
              product[ele].forEach((mainSpec: any) => {
                let spec: any = [];
                mainSpec?.specs?.forEach((subSpec: any) => {
                  spec.push(mainSpec?.displayName);
                  spec.push(subSpec?.displayName);
                  spec.push(subSpec?.value?.toString());
                });
                specList.push(spec?.join("~"));
              });
              row.push(specList?.join("|"));
            } else {
              row.push("");
            }
            break;
          case "keyTechSpecs":
            if (product[ele]?.length > 0) {
              let specList: any = [];
              product[ele].forEach((mainSpec: any) => {
                let spec: any = [];
                spec.push(mainSpec?.displayName);
                spec.push(mainSpec?.value?.toString());
                specList.push(spec?.join("~"));
              });
              row.push(specList?.join("|"));
            } else {
              row.push("");
            }
            break;
          case "proBenefit":
          case "proOverview":
            if (product[ele]?.length > 0) {
              let resultList: any = [];
              product[ele].forEach((res: any) => {
                resultList.push(res?.bodyCopy);
              });
              row.push(resultList?.join("|"));
            } else {
              row.push("");
            }
            break;
          case "accessories":
            if (product[ele]?.length > 0) {
              let resultList: any = [];
              product[ele].forEach((res: any) => {
                let subList: any = [];
                subList.push(res?.productDisplayName);
                subList.push(res?.headlineDescription);
                resultList.push(subList?.join("~"));
              });
              row.push(resultList?.join("|"));
            } else {
              row.push("");
            }
            break;
          default:
            row.push(product[ele] ? product[ele]?.toString() : "");
        }
      });
      data.push(row);
    });
    let replacedArr: any = data.map((row: any) =>
      row.map((field: any) => field?.replaceAll(",", ";"))
    );

    return (
      header + "\n" + replacedArr.map((row: any) => row.join(",")).join("\n")
    );
  };

  const productContentData = useSelector(
    (state: any) => state?.productReducer?.productContentData
  );

  useEffect(() => {
    if (!_.isEmpty(productContentData?.prodList) && isDownloadTriggered) {
      if (queryType === QUERY_TYPE.DIGITAL_ASSET) {
        let productNames: any = [];
        productContentData?.prodList?.forEach((ele: any) => {
          if (ele?.product?.index?.materialName)
            productNames.push(ele?.product?.index?.materialName);
        });
        if (productNames?.length >= 1) {
          dispatch(fetchProductDetailsData({ productNames: productNames }));
        }
      }
    }
  }, [productContentData]);

  const [isDownloadTriggered, setIsDownloadTriggered] = useState<any>(false);

  const downloadHandler = () => {
    setErrorMessage("");
    setIsDownloadTriggered(true);
    if (isValidData()) {
      if (queryType === QUERY_TYPE.DIGITAL_ASSET) {
        if (!_.isEmpty(selectedCategory) && selectedCategory !== "") {
          dispatch(
            fetchProductContentData({
              categoryName: [selectedCategory],
              digitalAssetMode:
                queryType === QUERY_TYPE.DIGITAL_ASSET ? true : false,
            })
          );
        } else if (!_.isEmpty(selectedProducts)) {
          dispatch(
            getProductsByMaterialName({
              materialName: selectedProducts,
              appendCategoryName: true,
            })
          );
        }
      } else if (queryType === QUERY_TYPE.PRODUCT_DATA) {
        const subSelectedDataType: any = [];

        selectedDataTypes.map((mainDataTypes: any) => {
          ogConfig?.productData.subDataTypes?.[mainDataTypes].forEach(
            (subDataType: any) => {
              subSelectedDataType.push(dataMapping[subDataType]);
            }
          );
        });

        dispatch(
          downloadDataFile({
            selectedDataTypes: subSelectedDataType,
            selectedProducts,
            selectedCategory,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (
      !_.isEmpty(productDataDownload) &&
      !_.isEmpty(selectedDataTypes) &&
      isDownloadTriggered
    ) {
      downloadData();
    }
  }, [productDataDownload]);

  const isValidData = () => {
    if (
      selectedDataTypes?.length <= 0 ||
      (radioDr === 1 && selectedProducts?.length <= 0) ||
      (radioDr === 2 && selectedCategory === "")
    ) {
      setErrorMessage(t("dataDownload.validationError.text"));
      return false;
    } else if (fileName) {
      if (fileName?.length > 100) {
        setErrorMessage(
          t("dataDownload.filenameMaxlenError.text").concat(" 100")
        );
        return false;
      } else if (!FILE_NAME_PATTERN.test(fileName)) {
        setErrorMessage(t("dataDownload.filenameInvalidError.text"));
        return false;
      }
    }
    return true;
  };

  //Deals with handling selecting and deleting selected categories
  const [selectedCategory, setSelectedCategory] = useState<any>("");
  const handleChipDelete = (e: any) => {
    setSelectedCategory("");
  };
  const getSelectedCategory = () => {
    return categories?.filter((cat: any) => cat?.value === selectedCategory)[0]
      ?.label;
  };

  const [searchValue, setSearchValue] = React.useState<any>("");

  //If the length of search text is greater than 2 chars, then trigger api call to fetch search result
  useEffect(() => {
    if (searchValue?.length > 2) {
      dispatch(
        searchDataDownloadProducts({
          //queryType,
          searchString: searchValue,
          digitalAssetMode:
            queryType === QUERY_TYPE.DIGITAL_ASSET ? true : false,
        })
      );
    }
  }, [searchValue]);

  //  If there are products selected from product page, it has to be added under selected products
  useEffect(() => {
    if (checkedProducts?.length > 0) {
      const data = checkedProducts.map(
        (element: any) => element.prodData.product.index.materialName
      );
      setSelectedProducts([...selectedProducts, ...data]);
    }
  }, []);

  //Add the selected products if it is not selected already
  const selectedProductHandler = (value: any) => {
    const index = selectedProducts?.findIndex((val: any) => val === value);
    if (index === -1) setSelectedProducts([...selectedProducts, value]);
  };

  //Delete the selected product chip
  const handleProductChipDelete = (value: any) => {
    const index = selectedProducts.findIndex((val: any) => val === value);
    if (index !== -1) {
      setSelectedProducts([
        ...selectedProducts.slice(0, index),
        ...selectedProducts.slice(index + 1),
      ]);
    }
  };

  //Resets all data when tabs are switched
  const resetAllData = () => {
    setRadioDr("1");
    setFileName("");
    setSelectedFileType(fileTypes[0]);
    setSelectedLanguage(languages[0]?.value);
    setSelectedCategory("");
    setSearchValue("");
    setIsDownloadTriggered(false);
  };

  //clears the category and products selected on switch on type
  const clearProductsAndCategory = () => {
    setSelectedCategory("");
    setSelectedProducts([]);
    setSearchValue("");
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={1}
        color="primary"
      >
        {t("dataDownload.title")}
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        sx={{ borderBottom: "1px solid #c8c8c8" }}
        className={styles.tabsStyle}
        TabIndicatorProps={{
          sx: {
            bottom: "4px",
            height: "1.5px",
          },
        }}
      >
        {digitalAssetDownloadAvailable && (
          <Tab
            label={t("dataDownload.digitalAssetDownload.title")}
            onClick={() => {
              navigate(
                `/${ROUTES_LABEL.DATA_DOWNLOAD}/${ROUTES_LABEL.DIGITAL_ASSET}`
              );
              setSelectedProducts([]);
              setQueryType(QUERY_TYPE.DIGITAL_ASSET);
            }}
          />
        )}
        {productDataDownloadAvailable && (
          <Tab
            label={t("dataDownload.productDataDownload.title")}
            onClick={() => {
              navigate(
                `/${ROUTES_LABEL.DATA_DOWNLOAD}/${ROUTES_LABEL.PRODUCT_DATA}`
              );
              setSelectedProducts([]);
              setQueryType(QUERY_TYPE.PRODUCT_DATA);
            }}
          />
        )}
      </Tabs>

      <Typography variant="body1" pt={2}>
        {t(`dataDownload.${queryType}.title`)}
      </Typography>
      <RadioGroup
        row
        value={radioDr}
        onChange={(e: any) => {
          setRadioDr(e.target.value);
          clearProductsAndCategory();
        }}
      >
        <FormControlLabel
          value="1"
          control={<Radio size="small" />}
          label={
            <Typography variant="body2" color="text.secondary">
              {t("dataDownload.searchProducts.title")}
            </Typography>
          }
        />
        <FormControlLabel
          value="2"
          control={<Radio size="small" />}
          label={
            <Typography variant="body2" color="text.secondary">
              {t("dataDownload.selectCategory.title")}
            </Typography>
          }
        />
      </RadioGroup>

      <Box mt={1}>
        {radioDr === "1" && (
          <>
            <Typography variant="body2">
              {t("dataDownload.searchProducts.title")}
            </Typography>
            <Typography
              component="div"
              variant="caption"
              color="text.secondary"
            >
              {t("dataDownload.searchProducts.text")}
            </Typography>
            <Box mt={2}>
              <Grid container>
                <Grid item lg={4} md={5} sm={12} xs={12}>
                  <Typography variant="body2" mb={0.5}>
                    {t("dataDownload.searchProducts.title")}
                  </Typography>
                  <CustomAutoComplete
                    options={productSearchResult}
                    value={searchValue}
                    setValue={setSearchValue}
                    placeHolder={t("dataDownload.placeHolder.search.text")}
                    selectedItems={(value: any) =>
                      selectedProductHandler(value)
                    }
                    textFieldStyle={{}}
                  />
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  &nbsp;
                </Grid>
                <Grid item lg={7} md={6} sm={12} xs={12}>
                  <Typography variant="body2" mb={0.5}>
                    {t("dataDownload.selectedProducts.title")}
                  </Typography>
                  {selectedProducts?.length > 0 ? (
                    <Box mt={0.5}>
                      {selectedProducts?.map((prod: any, index: any) => {
                        return (
                          <Chip
                            key={index}
                            sx={{ marginBottom: 1, marginRight: 1 }}
                            label={prod}
                            variant="outlined"
                            color="primary"
                            size="small"
                            onDelete={() => handleProductChipDelete(prod)}
                          />
                        );
                      })}
                    </Box>
                  ) : (
                    <span>-</span>
                  )}
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        {radioDr === "2" && (
          <>
            <Typography variant="body2">
              {t("dataDownload.selectCategory.title")}
            </Typography>
            <Typography
              component="div"
              variant="caption"
              color="text.secondary"
            >
              {t("dataDownload.selectCategory.text")}
            </Typography>
            <Box mt={2}>
              <Grid container>
                <Grid item lg={4} md={5} sm={12} xs={12}>
                  <Typography variant="body2" mb={0.5}>
                    {t("dataDownload.availableCategories.title")}
                  </Typography>
                  <CustomDropDown
                    value={selectedCategory}
                    handleChange={(e: any) => {
                      setSelectedCategory(e.target.value);
                    }}
                    menuOptions={categories}
                    module="debitNoteCreation"
                    label={
                      selectedCategory ? "" : t("dnet.select_category.text")
                    }
                  />
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  &nbsp;
                </Grid>
                <Grid item lg={4} md={5} sm={12} xs={12}>
                  <Typography variant="body2" mb={0.5}>
                    {t("dataDownload.selectedCategories.title")}
                  </Typography>
                  {selectedCategory ? (
                    <Chip
                      sx={{ marginTop: 0.5 }}
                      label={getSelectedCategory()}
                      variant="outlined"
                      color="primary"
                      onDelete={() => handleChipDelete(selectedCategory)}
                    />
                  ) : (
                    <span>-</span>
                  )}
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          {t("dataDownload.selectAssetType.title")}
        </Typography>
        <Typography component="div" variant="caption" color="text.secondary">
          {t("dataDownload.selectAssetType.text")}
        </Typography>
      </Box>

      <Box mt={2}>
        <CustomTransferList
          leftLabel={t("dataDownload.availableTypes.title")}
          rightLabel={t("dataDownload.selectedTypes.title")}
          leftList={leftList}
          rightList={rightList}
          selectedList={selectedListValueHandler}
        />
      </Box>

      {queryType === QUERY_TYPE.DIGITAL_ASSET && (
        <Box className={styles.infoContainer} mt={3}>
          <Typography
            component="div"
            variant="caption"
            color="text.secondary"
            dangerouslySetInnerHTML={{
              __html: isVisibleBaseLink
                ? t("dataDownload.baseInformation.text")
                : t("dataDownload.baseInformationDisabled.text"),
            }}
          ></Typography>
        </Box>
      )}

      <Box mt={2}>
        <Typography variant="body2">
          {t("dataDownload.inputFileName.title")}
        </Typography>
        <Typography component="div" variant="caption" color="text.secondary">
          {t("dataDownload.inputFileName.text")}
        </Typography>
        <Grid container mt={1}>
          <Grid item lg={9} md={11} sm={12} xs={12}>
            <CustomTextField
              additionalSxProperties={{ width: "100%" }}
              label=""
              changeHandler={fileNameHandler}
              value={fileName}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          {t("dataDownload.selectFormat.title")}
        </Typography>
        <RadioGroup
          row
          value={selectedFileType}
          onChange={(e: any) => {
            setSelectedFileType(e.target.value);
          }}
        >
          {fileTypes?.map((type: any, index: any) => {
            return (
              <FormControlLabel
                key={index}
                value={type}
                control={<Radio size="small" />}
                label={
                  <Typography variant="body2" color="text.secondary">
                    {type}
                  </Typography>
                }
              />
            );
          })}
        </RadioGroup>
      </Box>
      <Box mt={1}>
        <Typography variant="body2">
          {t("dataDownload.selectLanguage.title")}
        </Typography>
        <Grid container mt={0.5}>
          <Grid item lg={9} md={11} sm={12} xs={12}>
            <CustomDropDown
              value={selectedLanguage}
              handleChange={(e: any) => {
                setSelectedLanguage(e.target.value);
              }}
              menuOptions={languages}
              module="debitNoteCreation"
            />
          </Grid>
        </Grid>
      </Box>

      {errorMessage && (
        <Box mt={2} className={styles.errorStyle}>
          <Typography component="div" variant="caption">
            {errorMessage}
          </Typography>
        </Box>
      )}

      {displayRetailPriceWarning && (
        <Box mt={2} className={styles.disclaimerStyle}>
          <Typography component="div" variant="caption" color="text.secondary">
            {t("dataDownload.disclaimer.retailPrice.text")}
          </Typography>
        </Box>
      )}
      <Box mt={2}>
        <CustomButton
          label={t("dataDownload.download.title")}
          color="primary"
          onClick={downloadHandler}
          margin={0}
        />
      </Box>
    </>
  );
};
