import { Checkbox, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import CustomTextField from "../../atoms/customTextField";
import styles from "./quotation.module.scss";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomButton from "../../atoms/customButton";
// @ts-ignore
import { useReactToPrint } from "react-to-print";
import { QuotationTable } from "./quotationTable";
import moment from "moment";
import { SpecialInstructions } from "./specialInstructions";
import { useDispatch } from "react-redux";
import {
  placeSAPOrder,
  placeSAPOrderSuccess,
  saveQuoteRefData,
  saveQuoteSpecialInstructionData,
} from "../../../state/actions";
import CustomModal from "../../atoms/customModal";
var _ = require("lodash");
const hotIconFlag = require("../../../assets/images/icon_flag.gif");

export const Quotation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const componentRef = useRef<HTMLDivElement>(null);
  const [openSpecialInstructionModal, setOpenSpecialInstructionModal] =
    useState(false);
  const loginDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );
  const singleQuoteLoad = useSelector(
    (state: any) => state?.cartReducer?.singleQuoteLoad
  );
  const multiQuoteLoad = useSelector(
    (state: any) => state?.cartReducer?.multiQuoteLoad?.data
  );
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );
  const location = useLocation();
  const [specialInstructions, setSpecialInstructions] = useState<any>([]);
  const [isSpecialInstructionsActive, setIsSpecialInstructionsActive] =
    useState<any>(false);
  const [prodContentData, setProdContentData] = useState<any>([]);
  const [dealerProductDataState, setDealerProductDataState] =
    useState(singleQuoteLoad);

  useEffect(() => {
    if (!_.isEmpty(singleQuoteLoad)) {
      setProdContentData([singleQuoteLoad]);
      setDealerProductDataState(singleQuoteLoad);
      setTotalNetValue(singleQuoteLoad?.totalNetValue?.amount);
    }
    if (!_.isEmpty(multiQuoteLoad)) {
      setProdContentData(multiQuoteLoad?.response);
      setDealerProductDataState(multiQuoteLoad?.response?.[0]);
      let total = 0;
      multiQuoteLoad?.response?.forEach((item: any) => {
        total += item?.totalNetValue?.amount;
      });
      setTotalNetValue(total);
    }
  }, [singleQuoteLoad, multiQuoteLoad]);

  const quoteSpecialInstructions = useSelector(
    (state: any) => state?.cartReducer?.specialInstruction
  );

  useEffect(() => {
    if (dealerProductDataState?.specialInstructions?.length > 0) {
      let specInstructionsArr: any = [];
      specInstructionsArr = dealerProductDataState?.specialInstructions?.filter(
        (instruction: any) => {
          return instruction?.value === "enabled";
        }
      );
      if (
        specInstructionsArr?.length > 0 &&
        quoteSpecialInstructions?.length === 0
      ) {
        setIsSpecialInstructionsActive(true);
        specInstructionsArr?.forEach((item: any) => {
          let no = parseInt(item?.no) + 1;
          item.label = t(`dnet.special_instructions${no}.text`);
          item.check = false;
          item.fieldValue = "";
        });
        setSpecialInstructions(specInstructionsArr);
      } else {
        setIsSpecialInstructionsActive(true);
        setSpecialInstructions(quoteSpecialInstructions);
      }
    }
    let tempQuoteExtraObject = quoteExtraObject;
    tempQuoteExtraObject = {
      ...tempQuoteExtraObject,
      requestedDeliveryDate: dealerProductDataState?.requestedDeliveryDate,
    };
    setQuoteExtraObject(tempQuoteExtraObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerProductDataState]);

  let quoteCustomerReference = useSelector(
    (state: any) => state?.cartReducer?.customerReference
  );

  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  )

  let [quoteExtraObject, setQuoteExtraObject] = useState({
    expressDelivery: false,
    sendOrderByEmailCheck: false,
    sendOrderByEmailList: [loginDetails?.profile?.email],
    emailPrice: true,
    customerReference: quoteCustomerReference,
    requestedDeliveryDate: null,
    isVisited: false,
  });
  const accountFormat = (acc: any) => {
    const format = `${acc?.accNum} - ${acc?.accountName}, ${acc?.address?.street}, ${acc?.address?.townCity} (${acc?.address?.country})`;
    return format;
  };

  const currentDealerAccountFormat = () => {
    const format = `${currentDealer?.accNum} - ${currentDealer?.accountName}, ${currentDealer?.street}, ${currentDealer?.townCity} (${currentDealer?.country})`;
    return format;
  };

  const contextDealerAccFormat = (dealer: any) => {
    const format = `${dealer?.number} - ${dealer?.name}, ${dealer?.address?.street}, ${dealer?.address?.townCity} (${dealer?.address?.country})`;
    return format;
  };

  const shipToAccountFormat = (acc: any) => {
    const format = `${acc?.number} - ${acc?.name}, ${acc?.address?.street}, ${acc?.address?.townCity} (${acc?.address?.country})`;
    return format;
  };

  const [refError, setRefError] = useState(false);

  const handleDealerProductData = (fieldName: any, data: any) => {
    let orderInfo = quoteExtraObject;
    if (fieldName === "customerReference" && data !== "") {
      setRefError(false);
    }
    if (fieldName === "customerReference") {
      dispatch(
        saveQuoteRefData({
          customerReference: data,
        })
      );
    }
    orderInfo = {
      ...orderInfo,
      [fieldName]: data,
    };
    setQuoteExtraObject(orderInfo);
  };

  const quotationFormData = (editButtonCheck: boolean) => {
    return (
      <Grid container spacing={2} className={styles.gridCustomDesign}>
        <Grid item sm={2} xs={12}>
          <Typography variant="body2">{t("dnet.invoice_to.text")}</Typography>
        </Grid>
        <Grid item sm={10} xs={12}>
          <Typography variant="body2">
            {contextDealerAccFormat(saveAccountDetails?.invoiceToAcc)}
          </Typography>
        </Grid>
        {!_.isEmpty(dealerProductDataState?.shippingAgentsAccountIds) && (
          <>
            <Grid item sm={2} xs={12}>
              <Typography variant="body2">
                {t("dnet.shipping_agent.text")}
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12}>
              <Typography variant="body2">
                {accountFormat(
                  dealerProductDataState?.shippingAgentsAccountIds
                )}
              </Typography>
            </Grid>
          </>
        )}
        <Grid item sm={2} xs={12}>
          <Typography variant="body2">{t("dnet.deliver_to.text")}</Typography>
        </Grid>
        <Grid item sm={10} xs={12}>
          <Typography variant="body2">
            {location?.state?.selectedDeliverTo === "multiship"
              ? t("dnet.multiple_delivery.text")
              : shipToAccountFormat(dealerProductDataState?.shipToAccount)}
          </Typography>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Typography variant="body2">{t("dnet.payer.text")}</Typography>
        </Grid>
        <Grid item sm={10} xs={12}>
          <Typography variant="body2">
            {contextDealerAccFormat(saveAccountDetails?.payerToAcc)}
          </Typography>
        </Grid>
        {dealerProductDataState?.paymentTermEnabled === "true" &&
          !_.isEmpty(dealerProductDataState?.paymentTermId) && (
            <>
              <Grid item sm={2} xs={12}>
                <Typography variant="body2">
                  {t("dnet.payment_term.text")}
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12}>
                <Typography variant="body2">
                  {dealerProductDataState?.paymentTermId?.name +
                    "-" +
                    dealerProductDataState?.paymentTermId?.description}
                </Typography>
              </Grid>
            </>
          )}
        <Grid item sm={2} xs={12}>
          <Typography variant="body2">
            {t("dnet.requested_delivery_date.text")}
          </Typography>
        </Grid>
        <Grid item sm={10} xs={12}>
          <Typography variant="body2">
            {moment(dealerProductDataState?.requestedDeliveryDate)?.format(
              "DD-MMM-YYYY"
            )}
          </Typography>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Typography variant="body2">
            {t("dnet.your_reference.text")}
          </Typography>
        </Grid>
        <Grid item sm={10} xs={12}>
          <CustomTextField
            id="referenceNo"
            label=""
            value={quoteExtraObject?.customerReference}
            changeHandler={(e: any) => {
              handleDealerProductData("customerReference", e.target.value);
            }}
            helperText={refError && t("dnet.enter_reference.text")}
            error={refError}
          />
          <Tooltip
            title={t("dnet.mandatory.text")}
            arrow
            placement="right"
            enterTouchDelay={0}
          >
            <IconButton className={styles.infoIcon}>
              <InfoOutlinedIcon color="action" />
            </IconButton>
          </Tooltip>
        </Grid>
        {dealerProductDataState?.isCompleteDeliveryEnabled && (
          <>
            <Grid item sm={2} xs={12}>
              <Typography variant="body2">
                {t("dnet.delivery_type.text")}
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12}>
              <Typography variant="body2">
                {dealerProductDataState?.isPartialDelivery === false
                  ? t("dnet.complete.text")
                  : t("dnet.partial.text")}
              </Typography>
            </Grid>
          </>
        )}
        {isSpecialInstructionsActive && (
          <>
            <Grid item sm={2} xs={12}>
              <Typography variant="body2">
                {t("dnet.special_instructions_colon.text")}
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12}>
              {specialInstructions?.map((item: any) => {
                return (
                  <Typography variant="body2">
                    {item?.check && `${item?.label} : ${item?.fieldValue}`}
                  </Typography>
                );
              })}
              <div
                className={styles.editButton}
                style={editButtonCheck ? {} : { display: "none" }}
              >
                <CustomButton
                  id="edit"
                  label={t("dnet.edit.text")}
                  onClick={() => {
                    setOpenSpecialInstructionModal(
                      !openSpecialInstructionModal
                    );
                  }}
                  margin="0"
                />
              </div>
            </Grid>
          </>
        )}
        {exDeliveryFlag && (
          <>
            <Grid item sm={2} xs={12}>
              <Typography variant="body2">
                {t("dnet.express_delivery.text")}
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12}>
              <Checkbox
                id="expressDeliveryCheckbox"
                checked={quoteExtraObject?.expressDelivery}
                onChange={(e: any) => {
                  handleDealerProductData("expressDelivery", e.target.checked);
                }}
                size="small"
                sx={{ padding: 0, marginRight: "10px" }}
              />
            </Grid>
          </>
        )}
        <Grid item sm={2} xs={12}>
          <Typography variant="body2">
            {t("dnet.send_order_details_mail.text")}
          </Typography>
          {/*TODO: check further*/}
        </Grid>
        <Grid item sm={10} xs={12} className={styles.gridCustomDesign}>
          <Checkbox
            id="sendOrderByEmailCheck"
            checked={quoteExtraObject?.sendOrderByEmailCheck}
            onChange={(e: any) => {
              handleDealerProductData(
                "sendOrderByEmailCheck",
                e.target.checked
              );
            }}
            size="small"
            sx={{ padding: 0, marginRight: "10px" }}
          />
          {quoteExtraObject?.sendOrderByEmailCheck && (
            <div>
              <CustomTextField
                id="emailList"
                label={t("dnet.to.text")}
                value={quoteExtraObject?.sendOrderByEmailList?.join(",")}
                changeHandler={(e: any) => {
                  handleDealerProductData(
                    "sendOrderByEmailList",
                    e.target.value.split(",")
                  );
                }}
                //   helperText={refError && t("dnet.mandatory.text")}
                //   error={refError}
              />
              <Tooltip
                title={t("dnet.separate_multiple_addresses.text")}
                arrow
                placement="right"
                enterTouchDelay={0}
              >
                <IconButton className={styles.infoIcon}>
                  <InfoOutlinedIcon color="action" />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </Grid>
        {dealerProductDataState?.isSalesRepresentative === "true" && (
          <>
            <Grid item sm={2} xs={12}>
              <Typography variant="body2">
                {t("dnet.send_order_details_mail_to_all_contacts.text")}
              </Typography>
            </Grid>
            {salesRepresentativeContacts === "error" ? (
              <Grid item sm={10} xs={12}>
                {t("dnet.technical_error.text")}
              </Grid>
            ) : salesRepresentativeContacts === "empty" ? (
              <Grid item sm={10} xs={12}>
                {t("dnet.sales_rep_contact_email_list_empty.text")}
              </Grid>
            ) : (
              <Grid item sm={10} xs={12}>
                <Checkbox />
                <Typography variant="body2">
                  {salesRepresentativeContacts}
                </Typography>
              </Grid>
            )}
          </>
        )}
        {quoteExtraObject?.sendOrderByEmailCheck &&
          dealerProductDataState?.pricingInEmail && (
            <Grid item sm={2} xs={12}>
              <Typography variant="body2">
                {t("dnet.email_order_price.text")}
              </Typography>
              {/*TODO: check further*/}
            </Grid>
          )}
        {quoteExtraObject?.sendOrderByEmailCheck &&
          dealerProductDataState?.pricingInEmail && (
            <Grid item sm={10} xs={12} className={styles.gridCustomDesign}>
              <Checkbox
                id="priceInEmailCheckbox"
                checked={quoteExtraObject?.emailPrice}
                onChange={(e: any) => {
                  handleDealerProductData("emailPrice", e.target.checked);
                }}
                size="small"
                sx={{ padding: 0 }}
              />
            </Grid>
          )}
      </Grid>
    );
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const priceVisible = prodContentData[0]?.priceVisible;
  const [showPrice, setShowPrice] = useState(true);
  const [totalNetValue, setTotalNetValue] = useState<any>(0);

  const createTable = () => {
    const tableHeaderData = [
      { id: "line", label: t("dnet.line.text") },
      { id: "model", label: t("dnet.model.text") },
      { id: "orderedQuantity", label: t("dnet.ordered_quantity.text") },
      { id: "reqDate", label: t("dnet.req_date.text") },
      { id: "plannedQuantity", label: t("dnet.planned_quantity.text") },
      { id: "plannedDate", label: t("dnet.planned_date.text") },
      { id: "unitPrice", label: t("dnet.unit_price.text"), minWidth: "120px" },
      { id: "netPrice", label: t("dnet.netprice.text"), minWidth: "120px" },
      { id: "description", label: t("dnet.description.text") },
      { id: "specialPrice", label: t("dnet.special_price.text") },
    ];
    let specialInstructionsCheckForPrint = specialInstructions?.filter(
      (item: any) => item?.check
    );
    let cellStyle = {
      border: "1px solid #c8c8c8",
    };
    return (
      <div>
        <Typography
          variant="subtitle1"
          className={styles.header}
          pb={3}
          color="primary"
        >
          {t("dnet.quotation_title.text")}
        </Typography>
        <div>
          <Grid container spacing={2} className={styles.gridCustomDesign}>
            <Grid item sm={2} xs={12}>
              <Typography variant="body2">{t("dnet.date.text")}</Typography>
            </Grid>
            <Grid item sm={10} xs={12}>
              <Typography variant="body2">
                {moment(new Date()).format("DD-MMM-YYYY")}
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12}>
              <Typography variant="body2">
                {t("dnet.account_no.text")}
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12}>
              <Typography variant="body2">
                {currentDealerAccountFormat()}
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12}>
              <Typography variant="body2">
                {t("dnet.requested_delivery_date.text")}
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12}>
              <Typography variant="body2">
                {moment(dealerProductDataState?.requestedDeliveryDate)?.format(
                  "DD-MMM-YYYY"
                )}
              </Typography>
            </Grid>
            {isSpecialInstructionsActive && (
              <>
                <Grid item sm={2} xs={12}>
                  <Typography variant="body2">
                    {t("dnet.special_instructions_colon.text")}
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12}>
                  {specialInstructions?.length >= 1 &&
                  specialInstructionsCheckForPrint?.length > 0
                    ? specialInstructions?.map((item: any) => {
                        return (
                          <Typography variant="body2">
                            {item?.check &&
                              `${item?.label} : ${item?.fieldValue}`}
                          </Typography>
                        );
                      })
                    : t("dnet.none.text")}
                </Grid>
              </>
            )}
          </Grid>
          <div className={styles.tableBox} style={{ marginTop: "10px" }}>
            <div className={styles.tableContainer}>
              <table style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    {tableHeaderData.map((headCell: any, index: any) => (
                      <React.Fragment key={index}>
                        {headCell.id === "unitPrice" ? (
                          priceVisible && (
                            <td
                              key={headCell.id}
                              style={{
                                minWidth: headCell?.minWidth,
                                ...cellStyle,
                              }}
                            >
                              {headCell.label}
                            </td>
                          )
                        ) : headCell.id === "netPrice" ? (
                          <td
                            key={headCell.id}
                            style={{
                              minWidth: headCell?.minWidth,
                              ...cellStyle,
                            }}
                          >
                            {headCell.label}
                          </td>
                        ) : headCell.id === "specialPrice" ? (
                          prodContentData[0]?.isSpecialPriceEnabled && (
                            <td key={headCell.id} style={cellStyle}>
                              {headCell.label}
                            </td>
                          )
                        ) : headCell.id === "reqDate" ? (
                          location?.state?.multiRdd && (
                            <td key={headCell.id} style={cellStyle}>
                              {headCell.label}
                            </td>
                          )
                        ) : (
                          <td key={headCell.id} style={cellStyle}>
                            {headCell.label}
                          </td>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {prodContentData?.length > 0 ? (
                    prodContentData?.map((order: any, index: any) => {
                      return (
                        <>
                          {location?.state?.selectedDeliverTo ===
                            "multiship" && (
                            <tr
                              tabIndex={-1}
                              //   key={index}
                            >
                              <td
                                colSpan={tableHeaderData?.length - 1}
                                style={cellStyle}
                              >
                                <div>
                                  {`${t("dnet.deliver_to.text")} ${
                                    order?.shipToAccount?.number
                                  } - ${order?.shipToAccount?.name}, ${
                                    order?.shipToAccount?.address?.street
                                  }, ${
                                    order?.shipToAccount?.address?.townCity
                                  } (${
                                    order?.shipToAccount?.address?.country
                                  })`}
                                </div>
                              </td>
                            </tr>
                          )}
                          {order?.lineItems?.map(
                            (product: any, productIndex: any) => {
                              return (
                                <tr tabIndex={-1} key={index}>
                                  <td style={cellStyle}>
                                    {parseInt(product?.index, 10).toString()}
                                    {product?.hotflagged && (
                                      <img
                                        src={hotIconFlag}
                                        alt="hotIconFlag"
                                      />
                                    )}
                                  </td>
                                  <td style={cellStyle}>
                                    {product?.productSku?.name}
                                  </td>
                                  <td style={cellStyle}>{product?.quantity}</td>
                                  {location?.state?.multiRdd && (
                                    <td style={cellStyle}>
                                      {moment(
                                        product?.requestedDeliveryDate
                                      )?.format("DD-MMM-YYYY")}
                                    </td>
                                  )}
                                  {product?.scheduleLines?.length > 0 ? (
                                    <td style={cellStyle}>
                                      {product?.scheduleLines[0]?.quantity}
                                    </td>
                                  ) : (
                                    <td style={cellStyle}></td>
                                  )}
                                  {product?.scheduleLines?.length > 0 ? (
                                    <td style={cellStyle}>
                                      {product?.scheduleLines[0]?.confirmed
                                        ? moment(
                                            product?.scheduleLines[0]
                                              ?.goodsIssueDate
                                          )?.format("DD-MMM-YYYY")
                                        : product?.centralStockStatus
                                        ? t("dnet.centralstock_overnight.text")
                                        : t("dnet.unconfirmed.text")}
                                    </td>
                                  ) : (
                                    <td style={cellStyle}></td>
                                  )}
                                  {priceVisible && (
                                    <td style={cellStyle}>
                                      {showPrice &&
                                        `${product?.unitPrice?.currency} ${(product?.unitPrice?.amount)?.toFixed(2)}`}
                                    </td>
                                  )}
                                  <td style={cellStyle}>
                                    {showPrice &&
                                      `${product?.netValuePrice?.currency} ${(product?.netValuePrice?.amount)?.toFixed(2)}`}
                                  </td>
                                  <td style={cellStyle}>
                                    {
                                      product?.productSku?.descriptions
                                        ?.description
                                    }
                                  </td>
                                  {prodContentData[0]
                                    ?.isSpecialPriceEnabled && (
                                    <td style={cellStyle}>
                                      {product?.specialPrice &&
                                        product?.specialPrice?.amount &&
                                        `${product?.specialPrice?.currency} ${product?.specialPrice?.amount}`}
                                    </td>
                                  )}
                                </tr>
                              );
                            }
                          )}
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        style={cellStyle}
                        colSpan={8}
                        align="center"
                        className={styles.noRecFound}
                      >
                        {t("gzl.no_data_available.text")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={styles.tableFooterDesign}>
              {prodContentData[0]?.belowQuantityPrice?.amount > 0 && (
                <div className={styles.totalAmount}>
                  <Typography variant="body2">
                    {t("dnet.total_fee.text")}
                  </Typography>
                  {showPrice && (
                    <Typography variant="body2">
                      {`${prodContentData[0]?.belowQuantityPrice?.currency} ${prodContentData[0]?.belowQuantityPrice?.amount}`}
                    </Typography>
                  )}
                </div>
              )}
              <div className={styles.totalAmount}>
                <Typography variant="body2">
                  {`${t("dnet.total.text")} ${t(
                    "dnet.order_report.net_price.text"
                  )}`}
                </Typography>
                {showPrice && (
                  <Typography variant="body2">
                    {`${
                      prodContentData[0]?.totalNetValue?.currency
                    } ${totalNetValue?.toFixed(2)}`}
                  </Typography>
                )}
              </div>
            </div>
          </div>
          <div className={styles.endMsg}>
            <Typography variant="body2">
              {t(
                `dnet.quotationt.c_${currentDealer?.bgName?.slice(0, 4)}.text`
              ).toString()}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const [placeOrderClicked, setPlaceOrderClicked] = useState(false);
  const [isVisited, setIsVisited] = useState(false);

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );

  const handlePlaceOrder = () => {
    if (
      quoteExtraObject?.customerReference === "" ||
      quoteExtraObject?.customerReference === null
    ) {
      setRefError(true);
    } else if (location?.state?.selectedDeliverTo === "multiship") {
      let payloadSpecialInst = specialInstructions?.filter(
        (item: any) => item?.check
      );
      let payload = {
        products: prodContentData,
        formFields: quoteExtraObject,
        multiShipto: true,
        specialInstructions: payloadSpecialInst,
        usernameOrEmail: userDetails?.username,
      };
      payload.formFields = {
        ...payload?.formFields,
        isVisited: isVisited,
      };
      dispatch(placeSAPOrder(payload));
      !isVisited && setPlaceOrderClicked(true);
    } else {
      let payload = {
        ...dealerProductDataState,
        ...quoteExtraObject,
        isMultiRequestedDeliveryDate: location?.state?.multiRdd ? true : false,
        usernameOrEmail: userDetails?.username,
      };
      payload.expressDeliveryCheck = quoteExtraObject?.expressDelivery;
      payload.sendOrderByEmail = {
        check: quoteExtraObject?.sendOrderByEmailCheck,
        mails: quoteExtraObject?.sendOrderByEmailList,
      };
      payload.emailPrice = quoteExtraObject?.emailPrice;
      payload.isVisited = isVisited;
      let payloadSpecialInst = specialInstructions?.filter(
        (item: any) => item?.check
      );
      payload.specialInstructions = payloadSpecialInst;
      dealerProductDataState.customerReference =
        quoteExtraObject?.customerReference;
      dispatch(placeSAPOrder(payload));
      !isVisited && setPlaceOrderClicked(true);
    }
  };
  const handleContinuePlaceOrder = () => {
    if (
      quoteExtraObject?.customerReference === "" ||
      quoteExtraObject?.customerReference === null
    ) {
      setRefError(true);
    } else if (location?.state?.selectedDeliverTo === "multiship") {
      let payloadSpecialInst = specialInstructions?.filter(
        (item: any) => item?.check
      );
      let payload = {
        products: prodContentData,
        formFields: quoteExtraObject,
        multiShipto: true,
        specialInstructions: payloadSpecialInst,
        usernameOrEmail: userDetails?.username,
      };
      payload.formFields = {
        ...payload?.formFields,
        isVisited: true,
      };
      dispatch(placeSAPOrder(payload));
      setPlaceOrderClicked(false);
    } else {
      let payload = {
        ...dealerProductDataState,
        ...quoteExtraObject,
        isMultiRequestedDeliveryDate: location?.state?.multiRdd ? true : false,
        usernameOrEmail: userDetails?.username,
      };
      payload.expressDeliveryCheck = quoteExtraObject?.expressDelivery;
      payload.sendOrderByEmail = {
        check: quoteExtraObject?.sendOrderByEmailCheck,
        mails: quoteExtraObject?.sendOrderByEmailList,
      };
      payload.emailPrice = quoteExtraObject?.emailPrice;
      payload.isVisited = true;
      let payloadSpecialInst = specialInstructions?.filter(
        (item: any) => item?.check
      );
      payload.specialInstructions = payloadSpecialInst;
      dealerProductDataState.customerReference =
        quoteExtraObject?.customerReference;
      dispatch(placeSAPOrder(payload));
      setPlaceOrderClicked(false);
    }
  };
  const placeOrderRes = useSelector(
    (state: any) => state?.cartReducer?.placeOrderRes
  );
  const [topPageError, setTopPageError] = useState("");
  useEffect(() => {
    if (placeOrderRes?.success && placeOrderClicked) {
      if (_.isEmpty(placeOrderRes?.data)) {
        setTopPageError(t("dnet.cart_generic_error.text")?.toString());
      } else if (
        _.isEmpty(placeOrderRes?.data?.output) &&
        placeOrderRes?.message?.includes("Customer number already present")
      ) {
        setTopPageError("");
        setOpenModal(true);
      } else {
        setTopPageError("");
        navigateHandler();
      }
    } else if (placeOrderRes?.success && isVisited) {
      if (_.isEmpty(placeOrderRes?.data)) {
        setTopPageError(t("dnet.cart_generic_error.text")?.toString());
      } else {
        setTopPageError("");
        navigateHandler();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeOrderRes]);

  useEffect(() => {
    dispatch(placeSAPOrderSuccess({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const exDeliveryFlag = dealerProductDataState?.expressDelivery === "true"; //CONTEXT values
  const salesRepresentativeContacts: any = ""; //TODO: context
  const closeModal: any = () => {
    setOpenSpecialInstructionModal(false);
    setOpenModal(false);
  };
  const sendSpecialInstructions = (specialInstructions: any) => {
    dispatch(
      saveQuoteSpecialInstructionData({
        specialInstruction: specialInstructions,
      })
    );
    setSpecialInstructions(specialInstructions);
  };

  const [openModal, setOpenModal] = React.useState(false);

  const continueHandler = () => {
    setPlaceOrderClicked(false);
    setIsVisited(true);
    handleContinuePlaceOrder();
  };
  const navigateHandler = () => {
    navigate("/Order_Entry/Quotation/Receipt_Of_Order", {
      state: {
        customerReference: quoteExtraObject?.customerReference,
        deliverTo: location?.state?.selectedDeliverTo,
        multiRdd: location?.state?.multiRdd,
      },
    });
  };

  return (
    <div>
      {!openModal ? (
        <div>
          <Typography
            variant="subtitle1"
            className={styles.header}
            pb={3}
            color="primary"
          >
            {t("dnet.quotation_title.text")}
          </Typography>
          <div>
            {topPageError !== "" && (
              <div>
                <div className={styles.errorBackground}>
                  <Typography variant="body2">{topPageError}</Typography>
                </div>
              </div>
            )}
            {quotationFormData(true)}
            <div className={styles.buttonSection}>
              <div>
                <CustomButton
                  id="printer_friendly"
                  label={t("dnet.printer_friendly.text")}
                  onClick={handlePrint}
                  margin="0"
                />
                <div style={{ display: "none" }}>
                  <div ref={componentRef} style={{ padding: "22px" }}>
                    {createTable()}
                  </div>
                </div>
              </div>
              <CustomButton
                id="modify"
                label={t("dnet.modify.text")}
                onClick={() => {
                  navigate("/Order_Entry");
                }}
                color="primary"
                margin="0"
              />
              <CustomButton
                id="place_order"
                label={t("dnet.place_order.text")}
                onClick={handlePlaceOrder}
                margin="0"
              />
            </div>
            <QuotationTable
              isMultiShip={
                location?.state?.selectedDeliverTo === "multiship"
                  ? true
                  : false
              }
              multiRdd={location?.state?.multiRdd}
              showPrice={showPrice}
              setShowPrice={setShowPrice}
              placeOrderPage={false}
            />
            <div className={styles.endMsg}>
              <Typography variant="body2">
                {t(
                  `dnet.quotationt.c_${currentDealer?.bgName?.slice(0, 4)}.text`
                ).toString()}
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <CustomModal
          open={openModal}
          closeModal={closeModal}
          heading={"1"}
          content={t("dnet.not_unique_order_ref.txt")?.toString()}
          placeOrder={true}
          navigateHandler={continueHandler}
        />
      )}
      {openSpecialInstructionModal && (
        <SpecialInstructions
          closeModal={closeModal}
          openModal={openSpecialInstructionModal}
          sendSpecialInstructions={sendSpecialInstructions}
          specialInstructionsData={specialInstructions}
        />
      )}
    </div>
  );
};
